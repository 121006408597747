
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        



































































































































.info {
  display: flex;
  flex-direction: column;

  @include mq(m) {
    flex-direction: row;
    justify-content: space-between;
    gap: 4rem;
  }

  @include mq(xl) {
    gap: 6rem;
  }
}

.info-row {
  display: flex;
  gap: 1.5rem;

  svg {
    margin-top: 2px;
  }
}

.info__content {
  @include mq(xl) {
    display: flex;
    padding: 3rem 0;
  }
}

.info__content__contact,
.info__content__schedule {
  padding: 4rem 0;
}

.info__content__contact {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  border-bottom: 1px solid $c-black;

  a {
    color: $tango;
    font-weight: 400;
  }

  a:hover {
    text-decoration: underline;
  }

  @include mq(xl) {
    padding: 0 4rem 0 0;
    border-right: 1px solid $c-black;
    border-bottom: 0;
  }
}

.info__content__address {
  flex-wrap: wrap;
  font-weight: 400;

  a {
    display: block;
    flex-basis: 100%;
  }

  svg {
    margin-top: 3px;
  }

  address {
    font-style: normal;
  }
}

.info__content__schedule {
  @include mq(xl) {
    padding: 0 0 0 4rem;
  }
}

.info__content__schedule__list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.info__content__schedule__list__item {
  display: flex;

  span:first-child {
    flex-shrink: 0;
    min-width: 12rem;
    font-weight: 400;
    white-space: nowrap;
  }

  ::v-deep small {
    display: block;
    margin-top: -5px;
    color: $monza;
    font-weight: 400;
  }
}

.info__map {
  flex: 1;
  min-height: 26rem;

  iframe {
    min-height: 26rem;
  }

  @include mq($until: m) {
    margin: 0 inside-col(-2);
  }
}
