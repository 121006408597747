
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        





























































.content-block,
[class*='content-block--'] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
}

.content-block__content {
  max-width: 88rem;
}

.content-block__cta {
  display: flex;
  align-items: center;
}
