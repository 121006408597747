
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        











































































































/* stylelint-disable declaration-no-important */
$bp: m;

.store,
[class*='store--'] {
  // prettier-ignore
  @include fluid(margin-top,(xxs: 140px, xxl: 220px));
}

.store__header {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 80px, xxl: 100px));

  display: flex;
  flex-direction: column;

  @include mq($bp) {
    flex-direction: row;
  }
}

.store__intro,
.store__icons,
.store__info {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 50px, xxl: 80px));
}

.store__header__title {
  flex: 1;
  margin-bottom: 4rem;

  @include mq($bp) {
    margin-right: 2rem;
    margin-bottom: 0;
  }
}

.store__header__title__back {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  span {
    margin-bottom: 0;
    font-weight: 400;
  }

  svg {
    width: 6rem;
    height: 6rem;
    padding: 1rem;
    transition: transform 0.15s;
  }

  &:hover {
    svg {
      transform: translateX(-20%);
    }
  }
}

.store__header__gallery {
  width: 100%;
  aspect-ratio: 16/10;

  @include mq($bp) {
    width: 50%;
  }
}

.store__nav {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 80px, xxl: 120px));

  display: flex;
  justify-content: space-between;
  gap: 4rem;
}

.store__nav__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  font-weight: 400;

  svg {
    transition: transform 0.15s;
  }

  &:hover {
    &:first-child svg {
      transform: translateX(-20%);
    }

    &:last-child svg {
      transform: translateX(20%);
    }
  }

  @include mq($until: xs) {
    flex-direction: column;
    align-items: flex-start;

    &:last-child {
      flex-direction: column-reverse;
      align-items: flex-end;
    }
  }

  @include mq(xs) {
    gap: 2;
  }
}

.store__full-cta {
  // prettier-ignore
  @include fluid(padding-top,(xxs: 60px, xxl: 80px));

  // prettier-ignore
  @include fluid(padding-bottom,(xxs: 60px, xxl: 80px));
}

::v-deep {
  [class*='cta--'][class*='--bg-picture'] {
    &::before {
      display: block;
    }

    .picture {
      img {
        object-fit: contain;
        object-position: center;
      }
    }

    .inner {
      padding-top: 0;
      padding-bottom: 0;
    }

    @include mq($until: m) {
      .inner {
        display: flex;
        flex-direction: column-reverse;
      }

      .picture {
        position: relative;
        right: auto;
        width: 100%;
        max-height: 30rem;
        margin-bottom: 2rem;
        aspect-ratio: 16/10;
      }
    }

    @include mq(xl) {
      .inner {
        position: relative;
      }
    }
  }
}
