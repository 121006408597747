
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        















































.icons,
[class*='icons--'] {
}

.icons__title {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 25px, xxl: 30px));
}

.icons__items-outer {
  display: flex;
  justify-content: flex-start;
}

.icons__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

.icons__items__item {
  // prettier-ignore
  @include fluid(font-size,(xxs: 12px, xxl: 15px));

  flex-shrink: 0;
  width: 9rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;

  img {
    margin: 0 auto;
  }
}
