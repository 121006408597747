
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        






















































































































@import '~flickity/dist/flickity.css';

.gallery__gallery-outer {
  position: relative;
  aspect-ratio: 16/10;
}

.gallery__gallery {
  position: relative;
  height: 100%;
  padding-bottom: 5rem;

  img {
    @include get-all-space;

    object-fit: cover;
  }

  ::v-deep .flickity-page-dots {
    bottom: 0;
  }

  ::v-deep .dot {
    width: 8px;
    height: 8px;
    margin: 0 1rem;
  }
}

.gallery__gallery__nav {
  @include center-x;

  bottom: 0;
  display: none;
  justify-content: space-between;

  .has-slider & {
    display: flex;
  }
}

.gallery__gallery__btn {
  display: block;
  line-height: 1;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transform: translateY(30%);
  transition: transform 0.15s;

  &.next:active {
    transform: translate(10%, 30%);
  }

  &.prev:active {
    transform: translate(-10%, 30%);
  }
}
